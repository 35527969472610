import BigNumber from 'bignumber.js'
import * as Color from 'color'
import { CurrencyType, ICurrency, IFee } from '../../api-interfaces'

const style = getComputedStyle(document.body)
const color = Color(style.getPropertyValue('--bs-primary').trim())

export class Currency implements ICurrency {
    public code: string
    public type: CurrencyType
    public name: string
    public exchangeRate: string
    public decimalPlaces: number
    public depositable: boolean
    public holdable: boolean
    public transferable: boolean
    public withdrawable: boolean
    public createdAt: string
    public updatedAt: string
    public color: string

    constructor(data: ICurrency) {
        Object.assign(this, data)
    }

    public static convert(
        amount: string | number,
        baseCurrency: ICurrency,
        counterCurrency: ICurrency,
        spreadFee?: IFee | null,
        spreadUp = true
    ): string {
        let exchangeRate = Currency.calculateExchangeRate(baseCurrency, counterCurrency)
        if (baseCurrency.code !== counterCurrency.code && spreadFee) {
            if (spreadUp) {
                exchangeRate = new BigNumber(exchangeRate).times(new BigNumber(1).plus(spreadFee?.relative || 0))
            } else {
                exchangeRate = new BigNumber(exchangeRate).times(new BigNumber(1).minus(spreadFee?.relative || 0))
            }
        }
        return new BigNumber(amount).times(exchangeRate).toFixed(8, 1)
    }

    public static calculateExchangeRate(baseCurrency: ICurrency, counterCurrency: ICurrency): BigNumber {
        return new BigNumber(counterCurrency.exchangeRate).div(baseCurrency.exchangeRate)
    }

    public getColor(factor = 0): string {
        return this.color || color.lighten((factor % 7) * 0.08).hex()
    }
}
