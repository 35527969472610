import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'
import { IUser } from '../api-interfaces'
import { WhitelabelService } from '../services/core/whitelabel.service'

@Component({
    selector: 'user-display',
    templateUrl: 'user.component.html',
})
export class UserComponent implements OnInit {
    public appName = environment.appName
    public displayName = this.whitelabelService.getDisplayName()
    @Input()
    public user: IUser
    @Input()
    public displayAccountNumber = false
    @Input()
    public displayBadges = true
    @Input()
    public maxCharacters = 30

    public userLink: string[] = []

    constructor(private whitelabelService: WhitelabelService, private router: Router) {}

    public ngOnInit(): void {
        if (this.user) {
            if (this.router.url.includes('admin')) {
                this.userLink = ['/admin/accounts/users', this.user.id]
            } else if (this.router.url.includes('merchant')) {
                this.userLink = ['/back-office/customers', this.user.id]
            }
        }
    }
}
