export const environment = {
    appName: 'neopay',
    displayName: 'Neopay',
    layout: 'default',
    cryptoDepositAddresses: {
        BTC: 'bc1q0d4ts4wre7eh96mv6dns09lzpj92knfvznflnx',
        BSV: '1P4TmVrjT856D2uiF3gZqpnC5TBYzwDtRu',
        ETH: '0x900A8Df166f5625AA80F821e38f1Acef922e1Fc0',
        LTC: 'Lh4y3TRQBZDXRL7gs9XChD2TSbsgnmUMHA',
        'USDC.erc20': '0x900A8Df166f5625AA80F821e38f1Acef922e1Fc0',
        'USDT.erc20': '0x900A8Df166f5625AA80F821e38f1Acef922e1Fc0',
        'USDT.bsc': '0x900A8Df166f5625AA80F821e38f1Acef922e1Fc0',
        'USDT.trc20': 'TGCLrwRXgGFNdN21cSBNM6grnZwQgW7R8Q',
        ISLM: '0xB1419AAEc52935003C89F7bb9647b7E5F1e27724',
        IPMT: '0xB1419AAEc52935003C89F7bb9647b7E5F1e27724',
        LINK: '0x900A8Df166f5625AA80F821e38f1Acef922e1Fc0',
    },
    kycWizard: 'sumsub',
    allowWhiteLabel: false,
}
