import {
    Association,
    File,
    IAddress,
    ICardProgram,
    IUser,
    IWhitelabel,
    Permission,
    Referrer,
    Risk,
    UserDetails,
    UserFlag,
    UserRole,
    UserSegment,
    UserStatus,
    UserType,
} from '../../api-interfaces'
import { Currency } from '../accounting/currency.model'

export const methodsOfAccountOpening = [
    { label: 'Face-to-face', value: 'face-to-face' },
    { label: 'Online', value: 'online' },
    { label: 'Intermediary', value: 'intermediary' },
    { label: 'Complexity of delivery channels', value: 'complex' },
]

export const watchlistOptions = [
    { label: 'Not on Watchlist, No PEP', value: 'no-watchlist-no-pep' },
    { label: 'Not on Watchlist, PEP', value: 'no-watchlist-pep' },
    { label: 'On Watchlist, No PEP', value: 'on-watchlist-no-pep' },
    { label: 'On Watchlist, PEP', value: 'on-watchlist-pep' },
    { label: 'Not Evaluated', value: 'not-evaluated' },
]

export const sourceOfIncomeOptions = [
    { label: 'Employment Income', value: 'employment-income' },
    { label: 'Bonuses and Commissions', value: 'bonuses-commissions' },
    { label: 'Investment Returns (e.g., Dividends, Interest)', value: 'investment-returns' },
    { label: 'Rental Income', value: 'rental-income' },
    { label: 'Pensions', value: 'pensions' },
    { label: 'Inheritance', value: 'inheritance' },
    { label: 'Gifts', value: 'gifts' },
    { label: 'Savings and Investments', value: 'savings-investments' },
    { label: 'Real Estate Holdings', value: 'real-estate-holdings' },
    { label: 'Inherited Wealth', value: 'inherited-wealth' },
    { label: 'Personal Business Ownership', value: 'personal-business-ownership' },
    { label: 'High-value Personal Assets (e.g., Art, Jewelry)', value: 'high-value-assets' },
]

export const industryOptions = [
    { label: 'Agriculture', value: 'agriculture' },
    { label: 'Manufacturing', value: 'manufacturing' },
    { label: 'Construction', value: 'construction' },
    { label: 'Retail and Wholesale', value: 'retail-and-wholesale' },
    { label: 'Transportation and Logistics', value: 'transportation-and-logistics' },
    { label: 'Information Technology', value: 'information-technology' },
    { label: 'Financial Services', value: 'financial-services' },
    { label: 'Healthcare', value: 'healthcare' },
    { label: 'Hospitality and Tourism', value: 'hospitality-and-tourism' },
    { label: 'Professional Services', value: 'professional-services' },
    { label: 'Real Estate', value: 'real-estate' },
    { label: 'Education', value: 'education' },
    { label: 'Entertainment and Media', value: 'entertainment-and-media' },
    { label: 'Non-Profit and Government', value: 'non-profit-and-government' },
]

export const occupationOptions = [
    { label: 'Retired/House Person', value: 'retired-house-person' },
    { label: 'Investor', value: 'investor' },
    { label: 'Unemployed', value: 'unemployed' },
]

export const legalStructureOptions = [
    { label: 'Association', value: 'association' },
    { label: 'Foundation', value: 'foundation' },
    { label: 'Mission', value: 'mission' },
    { label: 'Cooperative', value: 'cooperative' },
    { label: 'Corporation', value: 'corporation' },
    { label: 'Government (Agency)', value: 'government-agency' },
    { label: 'Government (Consulate)', value: 'government-consulate' },
    { label: 'Government (Municipality)', value: 'government-municipality' },
    {
        label: 'Government (Multinational/Regional Development Bank, Supranational Organization)',
        value: 'government-multinational',
    },
    { label: 'Government (State-Owned Body)', value: 'government-state-owned-body' },
    { label: 'Partnership', value: 'partnership' },
    { label: 'Trust', value: 'trust' },
    { label: 'Estate', value: 'estate' },
    { label: 'LLC', value: 'llc' },
    { label: 'Sole Proprietorship', value: 'sole-proprietorship' },
    { label: 'Pension Plan', value: 'pension-plan' },
    { label: 'Private Investment Company (PIC)', value: 'private-investment-company-pic' },
    {
        label: 'SPV, SPE, Special Investment Vehicle',
        value: 'spv-spe-special-investment-vehicle',
    },
    { label: 'Segregated Portfolio Company', value: 'segregated-portfolio-company' },
    { label: 'Self Employed', value: 'self-employed' },
]

export const businessPurposeOptions = [
    { label: 'Providing Services', value: 'providing-services' },
    { label: 'Selling Products', value: 'selling-products' },
    { label: 'Other', value: '' },
]

export const serviceOptions = [
    { label: 'International Remittance', value: 'international-remittance' },
    { label: 'Domestic Remittance', value: 'domestic-remittance' },
    { label: 'Digital Currency', value: 'digital-currency' },
    { label: 'Securitised Offerings', value: 'securitised-offerings' },
    { label: 'Prepaid Cards', value: 'prepaid-cards' },
    { label: 'Insurance', value: 'insurance' },
    { label: 'Payment Accounts', value: 'payment-accounts' },
    { label: 'Precious Metals Services', value: 'precious-metals-services' },
]

const sharedPurposeOfAccountOptions = [
    { label: 'Bill/Utilities Payment', value: 'bill-utilities-payment' },
    { label: 'Charitable Donations', value: 'charitable-donations' },
    { label: 'Commission / Bonus Payment', value: 'commission-bonus-payment' },
    { label: 'FX - Major Currencies', value: 'fx-major-currencies' },
    { label: 'FX - Non-Major Currencies', value: 'fx-non-major-currencies' },
    { label: 'Insurance Payment', value: 'insurance-payment' },
    { label: 'Investment / Securities', value: 'investment-securities' },
    { label: 'License/Subscription Fee', value: 'license-subscription-fee' },
    { label: 'Line of Credit', value: 'line-of-credit' },
    { label: 'Remittance (Cross-Border Payments)', value: 'remittance-cross-border' },
    { label: 'Tax Payment', value: 'tax-payment' },
]

export const personalPurposeOfAccountOptions = [
    { label: 'Employment Income', value: 'employment-income' },
    { label: 'Family Maintenance (Education, Medical, Travel)', value: 'family-maintenance' },
    { label: 'Investments in Bonds', value: 'investments-bonds' },
    { label: 'Investments in Stock Market', value: 'investments-stock-market' },
    { label: 'Large Purchase (e.g., Home, Car)', value: 'large-purchase' },
    { label: 'Long-Term Savings', value: 'long-term-savings' },
    { label: 'Payments of Everyday Expenses', value: 'everyday-expenses' },
    { label: 'Personal Transactions', value: 'personal-transactions' },
    { label: 'Retirement Investments', value: 'retirement-investments' },
    { label: 'Save Money for Dependents (e.g., Children, Grandchildren)', value: 'save-money-dependents' },
    { label: 'Send Money Abroad', value: 'send-money-abroad' },
    { label: 'Short-Term Investments', value: 'short-term-investments' },
    { label: 'Short-Term Savings', value: 'short-term-savings' },
].concat(sharedPurposeOfAccountOptions)

export const businessPurposeOfAccountOptions = [
    { label: 'Business Expenses / Transactions', value: 'business-expenses' },
    { label: 'Custody of Intangible Assets', value: 'custody-intangible-assets' },
    { label: 'Custody of Tangible Assets', value: 'custody-tangible-assets' },
    { label: 'E-commerce Transactions', value: 'ecommerce-transactions' },
    { label: 'Estate Planning', value: 'estate-planning' },
    { label: 'Trade Finance', value: 'trade-finance' },
    { label: 'Transport Payment', value: 'transport-payment' },
].concat(sharedPurposeOfAccountOptions)

export const businessSourceOfIncomeOptions = [
    { label: 'Sales Revenue', value: 'sales-revenue' },
    { label: 'Service Fees', value: 'service-fees' },
    { label: 'Licensing and Royalties', value: 'licensing-royalties' },
    { label: 'Investment Income', value: 'investment-income' },
    { label: 'Government Grants and Subsidies', value: 'government-grants-subsidies' },
    { label: 'Loans and Credit Lines', value: 'loans-credit-lines' },
    { label: 'Business Profits', value: 'business-profits' },
    { label: 'Retained Earnings', value: 'retained-earnings' },
    { label: 'Business Assets (e.g., Property, Equipment)', value: 'business-assets' },
    { label: 'Equity Investments', value: 'equity-investments' },
    { label: 'Intellectual Property', value: 'intellectual-property' },
]

export const complexLayersOptions = [
    { label: '1 Layer', value: '1' },
    { label: '2 Layers', value: '2' },
    { label: '3 Layers or more', value: '3-or-more' },
]

export class User implements IUser {
    public id: string
    public type: UserType
    public segment: UserSegment
    public accountNumber: string
    public email: string
    public status: UserStatus
    public role: UserRole | null
    public lastLogin: string | null
    public loginCount: number
    public permissions: Permission[] | null
    public twoFactor: boolean
    public riskRating: Risk
    public flags: UserFlag[]
    public approvedAt: Date | null
    public name: string
    public phoneCountryCode: string | null
    public phoneNumber: string | null
    public referrer: Referrer | null
    public preferredCurrency: Currency
    public billingCurrency: Currency
    public files: File[]
    public sendEmailNotifications: boolean
    public expectedTurnover: string | null
    public address: IAddress
    public details: UserDetails
    public association: Association | null
    public whitelabel: IWhitelabel | null
    public parent: User | null
    public cardProgram: ICardProgram | null
    public isManaged: boolean
    public createdAt: string
    public updatedAt: string
    public externalId: string
    public multiSig?: number
    public niumCustomerId?: string | null
    public accountOpeningFeePaidAt?: Date | null
    public merchantPermissions?: string[] | null
    public revenueShare?: boolean

    constructor(data: Partial<IUser>) {
        Object.assign(this, data)
        if (data.preferredCurrency) {
            this.preferredCurrency = new Currency(data.preferredCurrency)
        }
    }
}
