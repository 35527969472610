<form
    class="form-validation e2e-transaction-form"
    #transactionForm="ngForm"
    (ngSubmit)="submit()"
    [ngClass]="{ 'ng-submitted': transactionForm.submitted }"
    autocomplete="false"
    novalidate
>
    <div class="modal-header">
        <h5 class="modal-title">{{ buildTitle() }}</h5>
        <button type="button" class="btn btn-round close close-modal" (click)="activeModal.dismiss()">&times;</button>
    </div>
    <ng-container *ngIf="!item.id; else viewTransaction">
        <ul class="nav nav-tabs-alt nav-fill" *ngIf="item.isPlatformTransfer()">
            <li class="nav-item">
                <a
                    href
                    class="nav-link"
                    [ngClass]="{ active: item && item.baseWallet?.user === null }"
                    (click)="swapBetweenAddOrDeductFunds('add'); $event.preventDefault()"
                >
                    {{ 'common.add' | translate }}
                </a>
            </li>
            <li class="nav-item">
                <a
                    href
                    class="nav-link"
                    [ngClass]="{ active: item && item.counterWallet?.user === null }"
                    (click)="swapBetweenAddOrDeductFunds('deduct'); $event.preventDefault()"
                >
                    {{ 'common.deduct' | translate }}
                </a>
            </li>
        </ul>
        <ul class="nav nav-tabs-alt nav-fill" *ngIf="['transfer', 'deposit', 'withdrawal'].includes(item.type) && !item.isPlatformTransfer()">
            <li class="nav-item">
                <a href class="nav-link" [ngClass]="{ active: copy.type === 'deposit' }" (click)="changeType('deposit'); $event.preventDefault()">
                    {{ 'common.deposit' | translate }}
                </a>
            </li>
            <li class="nav-item">
                <a href class="nav-link" [ngClass]="{ active: copy.type === 'transfer' }" (click)="changeType('transfer'); $event.preventDefault()">
                    {{ 'common.transfer' | translate }}
                </a>
            </li>
            <li class="nav-item">
                <a
                    href
                    class="nav-link"
                    [ngClass]="{ active: copy.type === 'withdrawal' }"
                    (click)="changeType('withdrawal'); $event.preventDefault()"
                >
                    {{ 'common.withdrawal' | translate }}
                </a>
            </li>
        </ul>
        <div class="modal-body">
            <div *ngIf="copy.type && !['transfer', 'mint'].includes(copy.type)" class="row">
                <div class="col-24">
                    <div class="form-group">
                        <label for="method">{{ 'common.method' | translate }}</label>
                        <ng-select
                            name="method"
                            [placeholder]="'common.select-method' | translate"
                            [items]="activeTransactionMethods"
                            [searchable]="false"
                            [clearable]="false"
                            [(ngModel)]="copy.method"
                            (change)="resetBeneficiary(); assignDepositInstruction()"
                            #method="ngModel"
                            required
                        >
                            <ng-template ng-label-tmp let-item="item"
                                ><span class="text-uppercase">{{ item }}</span></ng-template
                            >
                            <ng-template ng-option-tmp let-item="item"
                                ><span class="text-uppercase">{{ item }}</span></ng-template
                            >
                        </ng-select>
                        <div *ngIf="method.invalid && (transactionForm.submitted || method.dirty)" class="error-messages">
                            <div class="form-control-feedback" *ngIf="method?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <ng-container *ngIf="copy.type !== 'mint' && !item.isPlatformTransfer()">
                    <div class="col-24" *ngIf="item.type !== 'deposit'">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label for="sender">{{ (item.type === 'withdrawal' ? 'common.customer' : 'common.sender') | translate }}</label>
                                <a *ngIf="item.type === 'transfer'" href (click)="$event.preventDefault(); sender = { user: null }">{{
                                    'common.set-to-platform' | translate
                                }}</a>
                            </div>
                            <ng-select
                                id="sender"
                                name="sender"
                                [placeholder]="(item.type === 'withdrawal' ? 'common.enter-customer' : 'common.enter-sender') | translate"
                                [(ngModel)]="sender"
                                #recipientInput="ngModel"
                                [items]="usersStream | async"
                                [typeahead]="userAutocomplete"
                                [clearable]="false"
                                (change)="resetBeneficiary()"
                                required
                            >
                                <ng-template ng-label-tmp let-item="item">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <user-display *ngIf="item" [user]="item.user"></user-display>
                                        <small *ngIf="item.user" class="text-medium ms-1">{{ item.user.email }}</small>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <user-avatar *ngIf="item" [user]="item.user" size="sm" elementClass="me-2"></user-avatar>
                                        <div class="w-100">
                                            <ng-container *ngIf="item.user; else platform">
                                                <div>{{ item.user.name }}</div>
                                                <small class="text-medium">{{ item.user.email }}</small>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                            <div *ngIf="recipientInput.invalid && (transactionForm.submitted || recipientInput.dirty)" class="error-messages">
                                <div class="form-control-feedback" *ngIf="recipientInput?.errors?.required">
                                    {{ 'common.this-is-required' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-24" *ngIf="item.type !== 'withdrawal'">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label for="recipient">{{ (item.type === 'deposit' ? 'common.customer' : 'common.recipient') | translate }}</label>
                                <a *ngIf="item.type === 'transfer'" href (click)="$event.preventDefault(); recipient = { user: null }">{{
                                    'common.set-to-platform' | translate
                                }}</a>
                            </div>
                            <ng-select
                                id="recipient"
                                name="recipient"
                                [placeholder]="(item.type === 'deposit' ? 'common.enter-customer' : 'common.enter-recipient') | translate"
                                [(ngModel)]="recipient"
                                #recipientInput="ngModel"
                                [items]="usersStream | async"
                                [typeahead]="userAutocomplete"
                                [clearable]="false"
                                required
                            >
                                <ng-template ng-label-tmp let-item="item">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <user-display *ngIf="item" [user]="item.user" size="sm"></user-display>
                                        <small *ngIf="item.user" class="text-medium ms-1">{{ item.user.email }}</small>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <user-avatar *ngIf="item" [user]="item.user" size="sm" elementClass="me-2"></user-avatar>
                                        <div class="w-100">
                                            <ng-container *ngIf="item.user; else platform">
                                                <div>{{ item.user.name }}</div>
                                                <small class="text-medium">{{ item.user.email }}</small>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-select>
                            <div *ngIf="recipientInput.invalid && (transactionForm.submitted || recipientInput.dirty)" class="error-messages">
                                <div class="form-control-feedback" *ngIf="recipientInput?.errors?.required">
                                    {{ 'common.this-is-required' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #platform>
                        <div>{{ displayName }}</div>
                        <small class="text-medium">{{ 'common.platform' | translate }}</small>
                    </ng-template>
                </ng-container>
                <div class="form-group col-sm-12">
                    <label for="amount">{{ 'common.amount' | translate }}</label>
                    <input
                        type="number"
                        class="form-control"
                        id="amount"
                        name="amount"
                        [placeholder]="'common.enter-amount' | translate"
                        [(ngModel)]="copy.amount"
                        #amount="ngModel"
                        required
                    />
                    <div *ngIf="amount.invalid && (transactionForm.submitted || amount.dirty)" class="error-messages">
                        <div class="form-control-feedback" *ngIf="amount?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                    </div>
                </div>
                <div class="form-group col-sm-12">
                    <label class="me-1" for="currency">{{ 'common.currency' | translate }}</label
                    ><fa-icon
                        *ngIf="item.type === 'deposit' && !copy.beneficiary?.accountNumber"
                        [ngbTooltip]="'common.no-deposit-instruction' | translate"
                        class="text-danger"
                        [icon]="faExclamationTriangle"
                    ></fa-icon>
                    <ng-select
                        name="currency"
                        [placeholder]="'common.select-currency' | translate"
                        [(ngModel)]="copy.currency"
                        [items]="activeCurrencies"
                        [clearable]="false"
                        [searchable]="false"
                        (change)="resetBeneficiary(); assignDepositInstruction()"
                        #currencyInput="ngModel"
                        required
                    >
                        <ng-template ng-label-tmp let-item="item">{{ item.code }} - {{ item.name }}</ng-template>
                        <ng-template ng-option-tmp let-item="item">{{ item.code }} - {{ item.name }}</ng-template>
                    </ng-select>
                    <div *ngIf="currencyInput.invalid && (transactionForm.submitted || currencyInput.dirty)" class="error-messages">
                        <div class="form-control-feedback" *ngIf="currencyInput?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                    </div>
                    <div
                        *ngIf="copy.type === 'deposit' && copy.currency"
                        class="mt-1 text-primary small d-flex justify-content-between align-items-center"
                    >
                        <span
                            >{{ 'common.root-balance' | translate }}: {{ getRootBalance() | amount : copy.currency?.decimalPlaces }}
                            {{ copy.currency?.code }}</span
                        >
                        <div class="btn-group">
                            <a href class="btn btn-success-air btn-sm" (click)="$event.preventDefault(); mint('generate')">
                                <fa-icon [icon]="faPlus"></fa-icon>
                            </a>
                            <a href class="btn btn-danger-air btn-sm" (click)="$event.preventDefault(); mint('destroy')">
                                <fa-icon [icon]="faMinus"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="form-group col-24" *ngIf="copy.type === 'withdrawal'">
                    <div class="w-100" *ngIf="copy.method && sender">
                        <a href (click)="$event.preventDefault(); openBeneficiaryBook()" class="float-end">{{
                            'common.show-beneficiaries' | translate
                        }}</a>
                    </div>
                    <beneficiary-details-form
                        *ngIf="copy.beneficiary"
                        [beneficiary]="copy.beneficiary"
                        [disabled]="!!copy.beneficiary?.id"
                        #beneficiaryDetailsForm
                    >
                    </beneficiary-details-form>
                </div>
                <div class="form-group col-24">
                    <label for="reference"
                        >{{ 'common.reference' | translate }}
                        <small class="text-muted">({{ 'common.optional' | translate | lowercase }})</small></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        id="reference"
                        name="reference"
                        [placeholder]="'common.enter-reference' | translate"
                        [(ngModel)]="copy.reference"
                        emptyToNull
                    />
                </div>
                <div class="form-group col-24">
                    <label for="comment"
                        >{{ 'common.comment' | translate }} <small class="text-muted">({{ 'common.optional' | translate | lowercase }})</small></label
                    >
                    <textarea class="form-control" id="comment" name="comment" [(ngModel)]="copy.comment" rows="3" emptyToNull></textarea>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #viewTransaction>
        <ng-container *ngIf="item.type === 'withdrawal'; else otherTypes">
            <ul class="nav nav-tabs-alt nav-fill">
                <li class="nav-item">
                    <a href class="nav-link active" (click)="$event.preventDefault()">
                        {{ 'common.overview' | translate }}
                    </a>
                </li>
                <li class="nav-item">
                    <a href class="nav-link" [ngClass]="{ active: currentTab === 'alerts' }" (click)="$event.preventDefault()"> Alerts </a>
                </li>
                <li class="nav-item">
                    <a href class="nav-link" [ngClass]="{ active: currentTab === 'log' }" (click)="$event.preventDefault()"> Audit Log </a>
                </li>
            </ul>
            <div class="modal-body">
                <div class="table-responsive">
                    <table class="table table-striped mb-3">
                        <tbody>
                            <tr>
                                <td class="px-4 py-3">Transaction ID</td>
                                <td class="px-4 py-3">
                                    <uuid [value]="item.id" ngClass="text-primary"></uuid>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-4 py-3">Account</td>
                                <td class="px-4 py-3">
                                    <user-display [user]="item.baseWallet ? item.baseWallet.user : undefined"> </user-display>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-4 py-3">Beneficiary</td>
                                <td class="px-4 py-3">
                                    <div class="mb-2">
                                        <p class="fw-bold mb-1">Account holder</p>
                                        <div>{{ item.beneficiary?.accountHolder }}</div>
                                        <div class="text-medium">{{ item.beneficiary?.accountNumber }}</div>
                                        <postal-address [address]="item.beneficiary?.accountHolderAddress"></postal-address>
                                    </div>
                                    <div class="mb-2">
                                        <p class="fw-bold mb-1">Bank Details</p>
                                        <div>{{ item.beneficiary?.bankName }}</div>
                                        <div>{{ item.beneficiary?.bankSwiftCode }}</div>
                                        <div>{{ item.beneficiary?.bankAddress?.country | countryIsoToName }}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-4 py-3">Purpose</td>
                                <td class="px-4 py-3">
                                    <div>{{ item.purpose }}</div>
                                </td>
                            </tr>
                            <tr *ngIf="item.comment">
                                <td class="px-4 py-3">Comment</td>
                                <td class="px-4 py-3">
                                    <div>{{ item.comment }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-4 py-3">Amount to Beneficiary</td>
                                <td class="px-4 py-3">
                                    <div class="text-primary">{{ item.settledAmount | amount : item.precision }} {{ item.currency.code }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-4 py-3">Service Fee</td>
                                <td class="px-4 py-3">
                                    <div>{{ item.totalFee | amount : item.precision }} {{ item.currency.code }}</div>
                                    <div class="text-medium small">
                                        (<span *ngIf="item.fixedFee">{{ item.fixedFee | amount : item.precision }} {{ item.currency.code }}</span>
                                        <span *ngIf="item.fixedFee && item.relativeFee"> + </span>
                                        <span *ngIf="item.relativeFee">{{ item.relativeFee | percentage : 3 }}</span
                                        >)
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-4 py-3 font-weight-bold">Total Amount Charged</td>
                                <td class="px-4 py-3">
                                    <div class="text-primary">{{ item.amount | amount : item.precision }} {{ item.currency.code }}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div
                    class="alert d-flex justify-content-between align-items-center"
                    [ngClass]="nameScreening.beneficiary.status.Label !== 'Clear' ? 'alert-danger' : 'alert-success'"
                    *ngIf="nameScreening?.beneficiary"
                >
                    <div>
                        Beneficiary Screening:
                        <a
                            [href]="nameScreening.beneficiary.link"
                            target="_blank"
                            [ngClass]="nameScreening.beneficiary.status.Label !== 'Clear' ? 'text-danger' : 'text-success'"
                        >
                            <b>{{ nameScreening.beneficiary.status.Label !== 'Clear' ? 'MATCH' : 'NO_MATCH' }}</b>
                        </a>
                    </div>

                    <span class="badge bg-warning" *ngIf="nameScreening.beneficiary.status.Label === 'Referred'">Unresolved</span>
                    <span
                        class="badge bg-success"
                        *ngIf="nameScreening.beneficiary.status.Label === 'Decisioned' && nameScreening.beneficiary.status.Action === 'Accept'"
                    >
                        Accept
                    </span>
                    <span
                        class="badge bg-danger"
                        *ngIf="nameScreening.beneficiary.status.Label === 'Decisioned' && nameScreening.beneficiary.status.Action === 'Decline'"
                    >
                        Decline
                    </span>
                </div>

                <div
                    class="alert d-flex justify-content-between align-items-center"
                    [ngClass]="nameScreening.remitter.status.Label !== 'Clear' ? 'alert-danger' : 'alert-success'"
                    *ngIf="nameScreening?.remitter"
                >
                    <div>
                        Remitter Screening:
                        <a
                            [href]="nameScreening.remitter.link"
                            target="_blank"
                            [ngClass]="nameScreening.remitter.status.Label !== 'Clear' ? 'text-danger' : 'text-success'"
                        >
                            <b>{{ nameScreening.remitter.status.Label !== 'Clear' ? 'MATCH' : 'NO_MATCH' }}</b>
                        </a>
                    </div>

                    <span class="badge bg-warning" *ngIf="nameScreening.remitter.status.Label === 'Referred'">Unresolved</span>
                    <span
                        class="badge bg-success"
                        *ngIf="nameScreening.remitter.status.Label === 'Decisioned' && nameScreening.remitter.status.Action === 'Accept'"
                    >
                        Accept
                    </span>
                    <span
                        class="badge bg-danger"
                        *ngIf="nameScreening.remitter.status.Label === 'Decisioned' && nameScreening.remitter.status.Action === 'Decline'"
                    >
                        Decline
                    </span>
                </div>

                <collapsible *ngIf="!isBitGoTransaction(item)">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group text-start">
                                <label for="external-reference">{{ 'common.external-reference' | translate }}</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="external-reference"
                                    name="external-reference"
                                    [(ngModel)]="copy.externalReference"
                                    emptyToNull
                                />
                            </div>
                            <div class="d-flex justify-content-end">
                                <button
                                    type="button"
                                    class="btn btn-success btn-w-sm"
                                    (click)="updateExternalReference()"
                                    [ngClass]="{ loading: isUpdating }"
                                    [disabled]="isUpdating"
                                >
                                    {{ 'common.update' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </collapsible>

                <h6 class="font-weight-bold font-md">Documents</h6>
                <div class="table-responsive mb-2">
                    <table class="table table-hover align-items-center table-borderless">
                        <tbody>
                            <!-- <tr class="bg-white clickable">
                                <th scope="row">
                                    <div class="d-flex align-items-center">
                                        <fa-icon [icon]="faFilePdf" size="2x" class="text-muted me-3"></fa-icon>
                                        <div>
                                            Beneficiary
                                            <div class="small text-muted">passport-ppetzold.pdf</div>
                                        </div>
                                    </div>
                                </th>
                                <td class="text-end" nowrap></td>
                            </tr>
                            <tr class="bg-white clickable">
                                <th scope="row">
                                    <div class="d-flex align-items-center">
                                        <fa-icon [icon]="faFilePdf" size="2x" class="text-muted me-3"></fa-icon>
                                        <div>
                                            Invoice
                                            <div class="small text-muted">invoice-0045125.pdf</div>
                                        </div>
                                    </div>
                                </th>
                                <td class="text-end" nowrap></td>
                            </tr> -->
                            <tr class="bg-white clickable" *ngFor="let file of existingBeneficiaryFiles">
                                <th scope="row">
                                    <file-item [file]="file"></file-item>
                                </th>
                                <td></td>
                            </tr>
                            <!-- <tr class="bg-white clickable" *ngFor="let file of existingFiles">
                                <th scope="row">
                                    <file-item [file]="file"></file-item>
                                </th>
                                <td></td>
                            </tr> -->
                        </tbody>
                    </table>
                    <transaction-documents [transaction]="item"></transaction-documents>
                </div>

                <h6 class="font-weight-bold font-md">Exports</h6>
                <div class="table-responsive">
                    <table class="table table-hover align-items-center table-borderless mb-0">
                        <tbody>
                            <tr class="bg-white clickable" (click)="openProformaInvoice()">
                                <th scope="row">
                                    <div class="d-flex align-items-center">
                                        <fa-icon [icon]="faFilePdf" size="2x" class="text-muted me-3"></fa-icon>
                                        <div>
                                            Proforma Invoice
                                            <div class="small text-muted">PDF</div>
                                        </div>
                                    </div>
                                </th>
                                <td class="text-end" nowrap></td>
                            </tr>
                            <tr class="bg-white clickable" (click)="downloadXml()">
                                <th scope="row">
                                    <div class="d-flex align-items-center">
                                        <fa-icon [icon]="faFileCode" size="2x" class="text-muted me-3"></fa-icon>
                                        <div>
                                            SEPA Credit Transfer
                                            <div class="small text-muted">XML (pain.001.001.03)</div>
                                        </div>
                                    </div>
                                </th>
                                <td class="text-end" nowrap></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ng-container>
        <ng-template #otherTypes>
            <div class="modal-body">
                <div class="row mb-2">
                    <div class="col-12">
                        <h4 class="mb-0">{{ item.type | startCase }}</h4>
                        <h5 class="text-muted">{{ item.method | startCase }}</h5>
                    </div>
                    <div class="col-12">
                        <h3 class="text-end mb-0">{{ item.settledAmount | amount : item.precision }} {{ item.currency.code }}</h3>
                        <div class="text-end">
                            <span
                                class="text-capitalize badge badge-lg"
                                [ngClass]="{
                                    'bg-success': item.status === 'completed',
                                    'bg-danger': item.status === 'cancelled',
                                    'bg-primary': item.status === 'pending',
                                    'bg-warning': item.status === 'processing'
                                }"
                                >{{ item.status }}</span
                            >
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col pe-1" *ngIf="item.type !== 'deposit'">
                        <div class="card mb-0 p-2">
                            <div class="w-100 mb-1">
                                <p class="mb-0 font-weight-bold text-center">
                                    {{ (item.type === 'withdrawal' ? 'common.customer' : 'common.sender') | translate }}
                                </p>
                                <div
                                    *ngIf="copy.status === 'completed'"
                                    [ngbTooltip]="'common.new-balance' | translate"
                                    class="text-danger text-center"
                                >
                                    {{ item.baseNewBalance | amount : item.precision }} {{ item.currency.code }}
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <user-display
                                    [user]="item.baseWallet ? item.baseWallet?.user : undefined"
                                    [displayAccountNumber]="true"
                                    size="sm"
                                ></user-display>
                            </div>
                        </div>
                    </div>
                    <div class="col ps-1" *ngIf="item.type !== 'withdrawal'">
                        <div class="card mb-0 p-2">
                            <div class="w-100 mb-1">
                                <p class="mb-0 font-weight-bold text-center">
                                    {{ (item.type === 'deposit' ? 'common.customer' : 'common.receiver') | translate }}
                                </p>
                                <div
                                    *ngIf="copy.status === 'completed'"
                                    [ngbTooltip]="'common.new-balance' | translate"
                                    class="text-success text-center"
                                >
                                    {{ item.counterNewBalance | amount : item.precision }} {{ item.currency.code }}
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <user-display
                                    [user]="item.counterWallet ? item.counterWallet?.user : undefined"
                                    [displayAccountNumber]="true"
                                    size="sm"
                                ></user-display>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <ul class="nav nav-pills mb-2">
                            <ng-container>
                                <li class="nav-item">
                                    <a
                                        href
                                        class="nav-link"
                                        (click)="currentTab = 'details'; $event.preventDefault()"
                                        [ngClass]="{ active: currentTab === 'details' }"
                                        >{{ 'common.details' | translate }}</a
                                    >
                                </li>
                                <li *ngIf="item.beneficiary" class="nav-item">
                                    <a
                                        href
                                        class="nav-link"
                                        (click)="currentTab = 'beneficiary'; $event.preventDefault()"
                                        [ngClass]="{ active: currentTab === 'beneficiary' }"
                                        >{{ 'common.beneficiary' | translate }}</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        href
                                        class="nav-link"
                                        (click)="currentTab = 'files'; $event.preventDefault()"
                                        [ngClass]="{ active: currentTab === 'files' }"
                                        >{{ 'common.files' | translate }}</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        href
                                        class="nav-link"
                                        (click)="currentTab = 'logs'; $event.preventDefault()"
                                        [ngClass]="{ active: currentTab === 'logs' }"
                                        >{{ 'common.logs' | translate }}</a
                                    >
                                </li>
                                <li class="nav-item" *ngIf="item.method === 'crypto'">
                                    <a
                                        href
                                        class="nav-link"
                                        (click)="currentTab = 'chainalysis'; $event.preventDefault()"
                                        [ngClass]="{ active: currentTab === 'chainalysis' }"
                                        >Chainalysis</a
                                    >
                                </li>
                            </ng-container>
                        </ul>
                        <ng-container *ngIf="currentTab === 'details'">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td>{{ 'common.amount' | translate }}</td>
                                        <td class="text-end">{{ item.amount | amount : item.precision }} {{ item.currency.code }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ 'common.service-fee' | translate }}</td>
                                        <td class="text-end">
                                            <div>{{ item.totalFee | amount : item.precision }} {{ item.currency.code }}</div>
                                            <div class="text-muted">
                                                (
                                                <span *ngIf="item.fixedFee"
                                                    >{{ item.fixedFee | amount : item.precision }} {{ item.currency.code }}</span
                                                >
                                                <span *ngIf="item.fixedFee && item.relativeFee"> + </span>
                                                <span *ngIf="item.relativeFee">{{ item.relativeFee | percentage : 3 }}</span>
                                                )
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ 'common.settled-amount' | translate }}</td>
                                        <td class="text-end">
                                            <div>{{ item.settledAmount | amount : item.precision }} {{ item.currency.code }}</div>
                                            <div class="text-muted">
                                                ({{ item.amount | amount : item.precision }} {{ item.currency.code }} -
                                                {{ item.totalFee | amount : item.precision }} {{ item.currency.code }})
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="['deposit', 'withdrawal', 'transfer'].includes(item.type)">
                                        <td>{{ 'common.purpose' | translate }}</td>
                                        <td class="text-end">
                                            <span class="whitespace-normal">{{ item.purpose }}</span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="item.type === 'deposit'">
                                        <td>{{ 'common.source-of-funds' | translate | titlecase }}</td>
                                        <td class="text-end">
                                            <span class="whitespace-normal">{{ item.sourceOfFunds }}</span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="layout !== 'metal'">
                                        <td>{{ 'common.tags' | translate | lowercase }}</td>
                                        <td class="text-end text-break">
                                            <span *ngFor="let tag of item.tags" class="badge badge-lg bg-primary me-1">{{ tag | uppercase }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ 'common.reference' | translate }}</td>
                                        <td class="text-end text-break">
                                            <span class="whitespace-normal">{{ item.reference }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ 'common.external-reference' | translate }}</td>
                                        <td class="text-end text-break">
                                            <span class="whitespace-normal">{{ item.externalReference }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ 'common.comment' | translate }}</td>
                                        <td class="text-end text-break">
                                            <span class="whitespace-normal">{{ item.comment }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="text-end" *ngIf="item.id && ['withdrawal', 'deposit'].includes(item.type)">
                                <a href class="d-block mb-1" (click)="$event.preventDefault(); openProformaInvoice()"
                                    ><fa-icon [icon]="faFilePdf"></fa-icon> {{ 'common.download' | translate }}
                                    {{
                                        (item.method === 'payment-card' ? 'common.sales-invoice' : 'common.proforma-invoice') | translate | titlecase
                                    }}</a
                                >
                                <a *ngIf="item.type === 'withdrawal'" href class="d-block" (click)="$event.preventDefault(); downloadXml()"
                                    ><fa-icon [icon]="faFilePdf"></fa-icon> {{ 'common.download' | translate }} XML</a
                                >
                                <collapsible *ngIf="!isBitGoTransaction(item)">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="form-group text-start">
                                                <label for="external-reference">{{ 'common.external-reference' | translate }}</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="external-reference"
                                                    name="external-reference"
                                                    [(ngModel)]="copy.externalReference"
                                                    emptyToNull
                                                />
                                            </div>
                                            <div class="d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    class="btn btn-success btn-w-sm"
                                                    (click)="updateExternalReference()"
                                                    [ngClass]="{ loading: isUpdating }"
                                                    [disabled]="isUpdating"
                                                >
                                                    {{ 'common.update' | translate }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </collapsible>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="currentTab === 'beneficiary'">
                            <deposit-instruction-table [beneficiary]="item.beneficiary" [transaction]="item"></deposit-instruction-table>
                        </ng-container>
                        <ng-container *ngIf="currentTab === 'files'">
                            <transaction-documents [transaction]="item"></transaction-documents>
                        </ng-container>
                        <ng-container *ngIf="currentTab === 'logs'">
                            <activities [apiUrl]="'/transactions/' + item.id + '/activities'" [compact]="true"></activities>
                        </ng-container>
                        <ng-container *ngIf="currentTab === 'chainalysis'">
                            <chainalysis-table [transaction]="item"></chainalysis-table>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-template>
    <div class="modal-footer" *ngIf="!item.id || (item.id && item.status !== 'completed')">
        <ng-container *ngIf="item.id && item.status !== 'completed'">
            <button
                *ngIf="item.status === 'pending'"
                type="button"
                class="btn btn-primary btn-w-sm"
                (click)="start()"
                [ngClass]="{ loading: isChangingStatus }"
                [disabled]="isChangingStatus"
            >
                {{ 'common.start' | translate }}
            </button>
            <button
                *ngIf="item.status === 'processing' && isProcessable(item)"
                type="button"
                class="btn btn-success btn-w-sm"
                (click)="process()"
                [ngClass]="{ loading: isChangingStatus }"
                [disabled]="isChangingStatus"
            >
                {{ 'common.process' | translate }}
            </button>
            <button
                *ngIf="item.status === 'pending' || item.status === 'processing'"
                type="button"
                class="btn btn-danger btn-w-sm"
                (click)="cancel()"
                [ngClass]="{ loading: isChangingStatus }"
                [disabled]="isDeleting || isChangingStatus"
            >
                {{ 'common.cancel' | translate }}
            </button>
        </ng-container>
        <button
            *ngIf="!item.id"
            type="submit"
            class="btn btn-primary btn-w-sm"
            [ngClass]="{ loading: isSaving }"
            [disabled]="isSaving || (item.type === 'deposit' && !copy.beneficiary?.accountNumber)"
        >
            {{ 'common.create' | translate }}
        </button>
    </div>
</form>
