import { Pipe, PipeTransform } from '@angular/core'
import { countryMap } from './countries'

@Pipe({ name: 'countryIsoToName' })
export class CountryIsoToNamePipe implements PipeTransform {
    public transform(value: string | null): string {
        if (!value) {
            return ''
        }
        return countryMap[value].name
    }
}
