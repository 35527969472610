import { HttpClient } from '@angular/common/http'
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core'
import { capitalize } from 'lodash'
import { Subject, Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { BusinessDetails, IUser, UserDetails } from '../api-interfaces'
import { toCountryCallingCode, toStartCase } from '../common.mixin'
import { countryMap } from '../countries'
import { SessionService } from '../services/session.service'

@Component({
    selector: 'user-info-table',
    templateUrl: 'user-info-table.component.html',
})
export class UserInfoTableComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    public user: IUser
    public details: UserDetails | undefined
    public businessDetails: BusinessDetails | undefined
    public fields: (keyof UserDetails)[] = [
        'firstName',
        'middleName',
        'lastName',
        'gender',
        'phoneNumber',
        'dateOfBirth',
        'nationality',
        'address',
        'occupation',
        'sourceOfIncome',
        'taxIdentificationNumber',
        'fatca',
        'expectedTurnover',
        'documentType',
        'documentNumber',
        'documentIssuingDate',
        'documentExpiryDate',
        'proofOfAddressDate',
        'referredBy',
    ]
    public businessFields: (keyof BusinessDetails)[] = [
        'name',
        'type',
        'registrationNumber',
        'phoneNumber',
        'countryOfIncorporation',
        'dateOfIncorporation',
        'industry',
        'expectedTurnover',
        'address',
        'businessAddress',
        'regulatoryBody',
        'licenses',
        'fatca',
    ]
    public currentTab: 'business' | 'manager' = 'business'

    private fetchEvent = new Subject<void>()
    private subscriptions = new Subscription()

    public constructor(private http: HttpClient, private session: SessionService) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            this.fetchEvent
                .pipe(
                    switchMap(() =>
                        this.http.get<UserDetails>(
                            ['admin', 'licensee', 'support'].includes(this.session.user.role)
                                ? `/admin/users/${this.user.id}/details`
                                : `/users/${this.user.id}/details`
                        )
                    )
                )
                .subscribe(details => {
                    this.details = details
                    if (this.user.type === 'business') {
                        this.businessDetails = this.details.business!
                        this.fields = this.fields.filter(field => !['fatca', 'expectedTurnover'].includes(field))
                    }
                })
        )
        this.fetchEvent.next()
    }

    public ngOnChanges(): void {
        this.details = undefined
        this.businessDetails = undefined
        this.fetchEvent.next()
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public getProperty(property: keyof UserDetails): string {
        if (property === 'fatca') {
            return 'FATCA'
        }
        return toStartCase(property)
    }

    public getValue(property: keyof Omit<UserDetails, 'address' | 'business'>): string {
        if (!this.details) {
            return ''
        }
        if (property === 'fatca') {
            return this.details.fatca === undefined ? '' : this.details.fatca ? 'Yes' : 'No'
        }
        if (property === 'nationality') {
            return countryMap[this.details.nationality]?.name ?? ''
        }
        if (property === 'phoneNumber') {
            return `${toCountryCallingCode(this.details.phoneCountryCode) ?? ''} ${this.details.phoneNumber ?? ''}`
        }
        if (property === 'documentType') {
            return capitalize(this.details.documentType)
        }
        if (property === 'gender') {
            return capitalize(this.details.gender)
        }
        return (this.details[property] ?? '') as any
    }

    public getBusinessValue(property: keyof Omit<BusinessDetails, 'address' | 'businessAddress' | 'ubos'>): string {
        if (!this.businessDetails) {
            return ''
        }
        if (property === 'fatca' || property === 'licenses') {
            return this.businessDetails[property] === undefined ? '' : this.businessDetails[property] ? 'Yes' : 'No'
        }
        if (property === 'phoneNumber') {
            return `${toCountryCallingCode(this.businessDetails.phoneCountryCode) ?? ''} ${
                this.businessDetails.phoneNumber ?? ''
            }`
        }
        if (property === 'uboTree') {
            return JSON.stringify(this.businessDetails.uboTree)
        }
        return this.businessDetails[property] as any
    }
}
