import { Pipe, PipeTransform } from '@angular/core'
import BigNumber from 'bignumber.js'
import { Currency } from './models/accounting/currency.model'
import { CurrenciesQuery } from '../store/currencies/currencies.query'

@Pipe({ name: 'convert' })
export class ConvertPipe implements PipeTransform {
    public static readonly DEFAULT_DECIMAL_PLACES = 2

    constructor(private currenciesQuery: CurrenciesQuery) {}

    public transform(value: string | number, baseCurrencyCode: string, counterCurrencyCode: string): string | null {
        if (
            !value ||
            !this.currenciesQuery.hasEntity(baseCurrencyCode) ||
            !this.currenciesQuery.hasEntity(counterCurrencyCode)
        ) {
            return null
        }

        const baseCurrency = this.currenciesQuery.getEntity(baseCurrencyCode)!
        const counterCurrency = this.currenciesQuery.getEntity(counterCurrencyCode)!

        const converted = Currency.convert(value, baseCurrency, counterCurrency)

        return new BigNumber(converted).toFixed(counterCurrency.decimalPlaces)
    }
}
