import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { faInfoCircle, faPhone, faTrash } from '@fortawesome/pro-light-svg-icons'
import { faUser, faBuilding } from '@fortawesome/pro-regular-svg-icons'
import formatDate from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { find } from 'lodash'
import { Subscription } from 'rxjs'
import { BusinessDetails, LabelValue, UserDetails, UserDocumentType, UserGender, UserType } from '../api-interfaces'
import { searchCountryCode, toStartCase } from '../common.mixin'
import { countries, countriesSortedByName, Country } from '../countries'
import {
    businessPurposeOfAccountOptions,
    businessPurposeOptions,
    businessSourceOfIncomeOptions,
    complexLayersOptions,
    industryOptions,
    legalStructureOptions,
    methodsOfAccountOpening,
    occupationOptions,
    personalPurposeOfAccountOptions,
    serviceOptions,
    sourceOfIncomeOptions,
    watchlistOptions,
} from '../models/core/user.model'

/** Transforms masked date to YYYY-MM-DD */
export function formatMaskedDate(unformattedDate: string): string {
    return `${unformattedDate.slice(4)}-${unformattedDate.slice(2, 4)}-${unformattedDate.slice(0, 2)}`
}

@Component({
    selector: 'user-details-form',
    templateUrl: 'user-details-form.component.html',
})
export class UserDetailsFormComponent implements OnChanges, OnDestroy {
    @Input()
    public type: UserType = 'personal'
    @Input()
    public details: UserDetails = {} as UserDetails
    @Input()
    public requireAll = true
    @Input()
    public disabled = false

    public submitted = false

    @ViewChild('detailsForm')
    public form: NgForm

    public business = {} as BusinessDetails

    public searchCountryCode = searchCountryCode
    public toStartCase = toStartCase

    public faPhone = faPhone
    public faInfoCircle = faInfoCircle
    public faUser = faUser
    public faBuilding = faBuilding
    public faTrash = faTrash

    public genders: UserGender[] = ['male', 'female']
    public currentTab: 'business' | 'manager' | 'structure' = 'business'
    public countries = countries
    public documentTypes: UserDocumentType[] = ['Passport', 'ID Card']

    public countriesSortedByName = countriesSortedByName.map(country => ({
        label: country.name,
        value: country.alpha2,
    }))
    public legalStructureOptions = legalStructureOptions
    public industryOptions = industryOptions
    public businessPurposeOptions = businessPurposeOptions
    public serviceOptions = serviceOptions
    public sourceOfIncomeOptions = sourceOfIncomeOptions
    public occupationOptions = occupationOptions.concat(industryOptions)
    public methodsOfAccountOpening = methodsOfAccountOpening
    public watchlistOptions = watchlistOptions
    public businessPurposeOfAccountOptions = businessPurposeOfAccountOptions
    public businessSourceOfIncomeOptions = businessSourceOfIncomeOptions
    public complexLayersOptions = complexLayersOptions
    public personalPurposeOfAccountOptions = personalPurposeOfAccountOptions

    public dateOfBirth: string | null = ''
    public dateOfIncorporation: string | null = ''
    public phoneCountryCode: Country | null | undefined
    public businessPhoneCountryCode: Country | null | undefined
    public documentIssuingDate: string | null = ''
    public documentExpiryDate: string | null = ''
    public proofOfAddressDate: string | null = ''

    public newLicense: {
        licenseName: string
        licenseNumber: string
        expiryDate: string
        issuanceCountry: LabelValue
        authority: string
    } = {} as any
    public newLicenseSubmitted = false
    @ViewChild('licenseForm', { static: false }) licenseForm!: NgForm

    private subscriptions = new Subscription()

    constructor() {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.details) {
            this.phoneCountryCode = find(countries, country => country.alpha2 === this.details.phoneCountryCode)
            this.dateOfBirth = this.details.dateOfBirth
                ? formatDate(parseISO(this.details.dateOfBirth), 'ddMMyyyy')
                : null
            this.documentIssuingDate = this.details.documentIssuingDate
                ? formatDate(parseISO(this.details.documentIssuingDate), 'ddMMyyyy')
                : null
            this.documentExpiryDate = this.details.documentExpiryDate
                ? formatDate(parseISO(this.details.documentExpiryDate), 'ddMMyyyy')
                : null
            this.proofOfAddressDate = this.details.proofOfAddressDate
                ? formatDate(parseISO(this.details.proofOfAddressDate), 'ddMMyyyy')
                : null
            if (this.type === 'business') {
                this.business = this.details.business || ({} as any)
                this.businessPhoneCountryCode = find(
                    countries,
                    country => country.alpha2 === this.details.business?.phoneCountryCode
                )
                this.dateOfIncorporation = this.details.business?.dateOfIncorporation
                    ? formatDate(parseISO(this.details.business?.dateOfIncorporation), 'ddMMyyyy')
                    : null
            }
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public submit(): void {
        this.submitted = true
        if (this.dateOfBirth) {
            this.details.dateOfBirth = formatMaskedDate(this.dateOfBirth)
        }
        if (this.documentIssuingDate) {
            this.details.documentIssuingDate = formatMaskedDate(this.documentIssuingDate)
        }
        if (this.documentExpiryDate) {
            this.details.documentExpiryDate = formatMaskedDate(this.documentExpiryDate)
        }
        if (this.proofOfAddressDate) {
            this.details.proofOfAddressDate = formatMaskedDate(this.proofOfAddressDate)
        }
        if (this.phoneCountryCode) {
            this.details.phoneCountryCode = this.formatPhoneCountryCode(this.phoneCountryCode)
        }

        if (this.type === 'business') {
            if (this.dateOfIncorporation) {
                this.business.dateOfIncorporation = formatMaskedDate(this.dateOfIncorporation)
            }
            if (this.businessPhoneCountryCode) {
                this.business.phoneCountryCode = this.formatPhoneCountryCode(this.businessPhoneCountryCode)
            }
            Object.assign(this.details.business as any, this.business)
        }
    }

    public isValid(): boolean {
        return !!this.form.valid
    }

    public formatPhoneCountryCode(unformattedPhoneCountryCode: Country): string {
        return unformattedPhoneCountryCode.alpha2
    }

    public addLicense(): void {
        this.newLicenseSubmitted = true
        if (this.isLicenseFormValid()) {
            if (!this.business.licenses) {
                this.business.licenses = []
            }

            if (
                this.newLicense.licenseName &&
                this.newLicense.licenseNumber &&
                this.newLicense.expiryDate &&
                this.newLicense.issuanceCountry &&
                this.newLicense.authority
            ) {
                this.business.licenses.push({
                    licenseName: this.newLicense.licenseName,
                    licenseNumber: this.newLicense.licenseNumber,
                    expiryDate: formatMaskedDate(this.newLicense.expiryDate),
                    issuanceCountry: this.newLicense.issuanceCountry,
                    authority: this.newLicense.authority,
                })
                this.clearLicenseForm()
            }
        }
    }

    public removeLicense(index: number): void {
        if (this.business.licenses) {
            this.business.licenses.splice(index, 1)
        }
    }

    public isLicenseFormValid(): boolean {
        return (
            !!this.newLicense.licenseName &&
            !!this.newLicense.licenseNumber &&
            !!this.newLicense.expiryDate &&
            !!this.newLicense.issuanceCountry &&
            !!this.newLicense.authority
        )
    }

    public clearLicenseForm(): void {
        this.newLicense = {} as any
        this.newLicenseSubmitted = false
        this.licenseForm.resetForm()
    }
}
